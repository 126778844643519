<template>
    <div>
        <div style="display: flex;flex-direction: row;">
            <GgdCard background="#409EFF" title="流水" :content="amount"></GgdCard>
            <GgdCard background="#FF88A7" title="订单总量" :content="orderTotalCount" unit="个"></GgdCard>
            <GgdCard background="#7B5EEA" title="成单总量" :content="orderSuccessCount" unit="个"></GgdCard>
            <GgdCard background="#F19149" title="总拨打时长" :content="duration"></GgdCard>
            <GgdCard background="#FF88A7" title="拨打量" :content="dail" unit="个"></GgdCard>
            <GgdCard background="#7B5EEA" title="接通量" :content="effectiveQuantity" unit="个"></GgdCard>
            <GgdCard background="#F19149" title="有效量" :content="connectionVolume" unit="个"></GgdCard>
            <GgdCard background="#F19149" title="索取首咨总量" :content="consultCount" unit="个"></GgdCard>
        </div>
        <el-row style="margin-bottom: 20px;flex-wrap: wrap">
            <el-row type="flex" justify="start" :gutter="10">
                <el-col :span="3">
                    <el-checkbox v-model="Options.turnoverChecked" @change="OptionsChange">流水</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.turnoverRepiarChecked" @change="OptionsChange">补录流水</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.opportunitiesNumChecked" @change="OptionsChange">当前机会数</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.firstConsultationNumChecked" @change="OptionsChange">首咨数
                    </el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.stockCountNumChecked" @change="OptionsChange">领取库存</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.dialNumChecked" @change="OptionsChange">拨打量</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.frequencyChecked" @change="OptionsChange">拨打频次</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.callsNumChecked" @change="OptionsChange">拨打机会数</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.connectionVolumeChecked" @change="OptionsChange">接通量</el-checkbox>
                </el-col>
            </el-row>
            <el-row type="flex" justify="start" :gutter="10">
                <el-col :span="3">
                    <el-checkbox v-model="Options.connectionRateChecked" @change="OptionsChange">接通率</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.effectiveQuantityChecked" @change="OptionsChange">有效量</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.totalCallDurationChecked" @change="OptionsChange">总通话时长
                    </el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.averageDurationChecked" @change="OptionsChange">平均单通时长
                    </el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.orderVolumeChecked" @change="OptionsChange">订单量</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.singleQuantityChecked" @change="OptionsChange">成单量</el-checkbox>
                </el-col>
                <el-col :span="3">
                    <el-checkbox v-model="Options.conversionRateChecked" @change="OptionsChange">转化率</el-checkbox>
                </el-col>
            </el-row>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-row type="flex" justify="start" :gutter="10">
                <el-col :offset="0.1">
                    <el-checkbox-group v-model="showDeptList">
                        <el-checkbox :checked="true" v-for="item in deptList" :label="item.id"
                                     @change="(checked)=> handleDeptCheck(checked,item.id)" :key="item.id">
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-col>
            </el-row>
        </el-row>
        <el-row type="flex" justify="start" :gutter="10">
            <el-col :offset="0.1">
                <el-form :inline="true">
                    <!--          <el-form-item label="时间区间">-->
                    <!--            <el-select v-model="form.type" size="mini">-->
                    <!--              <el-option :value="1" label="今天"></el-option>-->
                    <!--              <el-option :value="2" label="昨天"></el-option>-->
                    <!--              <el-option :value="3" label="本月"></el-option>-->
                    <!--              <el-option :value="4" label="自定义"></el-option>-->
                    <!--            </el-select>-->
                    <!--          </el-form-item>-->
                    <el-form-item label="时间区间">
                        <el-date-picker
                                v-model="form.startToEndTime"
                                type="daterange"
                                align="right"
                                value-format="yyyy-MM-dd"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                size="mini"
                                :picker-options="startToEndTimeOptions">
                        </el-date-picker>
                        <!--            <el-date-picker v-model="form.startToEndTime" size="mini" type="daterange" range-separator="至"-->
                        <!--                            start-placeholder="开始日期"-->
                        <!--                            end-placeholder="结束日期">-->
                        <!--            </el-date-picker>-->
                    </el-form-item>
                    <el-form-item label="部门" prop="deptId">
                        <el-select
                                v-model="form.deptId"
                                filterable
                                placeholder="归属部门"
                                clearable
                                size="small"
                                style="width: 240px"
                        >
                            <el-option v-for="dept in deptList" :value="dept.id"
                                       :label="dept.title"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="咨询师">
                        <el-select v-model="form.accountId" class="condition-select" filterable placeholder="请选择"
                                   clearable
                                   size="mini">
                            <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="doSearch"></el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table
                :data="tableData" style="width: 100%;margin-bottom: 20px;" :row-key="getRowKeys" v-loading="loading"
                stripe
                border max-height="750" ref="dataTab"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column prop="deptName" label="部门" fixed="left" min-width="200"></el-table-column>
            <el-table-column prop="accountName" label="销售姓名" fixed="left" align="center"
                             min-width="100"></el-table-column>
            <el-table-column prop="status" label="人员状态" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.status === 1">在职</span>
                    <span v-else-if="scope.row.status === 2">离职</span>
                    <span v-else-if="scope.row.status === 0">暂停</span>
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="流水" align="center" v-if="Options.turnoverChecked"></el-table-column>
            <el-table-column prop="repairAmount" label="补录流水" align="center"
                             v-if="Options.turnoverRepiarChecked"></el-table-column>
            <el-table-column prop="opportunityCount" label="当前机会数" align="center" min-width="100"
                             v-if="Options.opportunitiesNumChecked"></el-table-column>
            <el-table-column prop="consultCount" label="领取首咨数" align="center" min-width="100"
                             v-if="Options.firstConsultationNumChecked"></el-table-column>
            <el-table-column prop="stockCount" label="领取库存数" align="center" min-width="100"
                             v-if="Options.stockCountNumChecked"></el-table-column>
            <el-table-column prop="callCount" label="拨打量" align="center"
                             v-if="Options.dialNumChecked"></el-table-column>
            <el-table-column prop="wechatCount" label="微信添加量" min-width="100" align="center"></el-table-column>
            <el-table-column prop="calledTimes" label="频次" align="center"
                             v-if="Options.frequencyChecked"></el-table-column>
            <el-table-column prop="callOpporCount" label="拨打机会数" align="center" width="120"
                             v-if="Options.callsNumChecked"></el-table-column>
            <el-table-column prop="callDailCount" label="接通量" align="center"
                             v-if="Options.connectionVolumeChecked"></el-table-column>
            <el-table-column prop="connectionVolume" label="接通率" align="center"
                             v-if="Options.connectionRateChecked"></el-table-column>
            <el-table-column prop="callValid" label="有效量" align="center"
                             v-if="Options.effectiveQuantityChecked"></el-table-column>
            <el-table-column label="总通话时长" align="center" width="120" v-if="Options.totalCallDurationChecked">
                <template slot-scope="slot">
                    {{ formatSeconds(slot.row.callTime) }}
                </template>
            </el-table-column>
            <el-table-column prop="avgCallTime" label="平均单通话时长" align="center" width="150"
                             v-if="Options.averageDurationChecked"></el-table-column>
            <el-table-column prop="orderTotal" label="订单量" align="center"
                             v-if="Options.orderVolumeChecked"></el-table-column>
            <el-table-column prop="orderCount" label="成单量" align="center"
                             v-if="Options.singleQuantityChecked"></el-table-column>
            <el-table-column prop="conversion" label="转化率" align="center"
                             v-if="Options.conversionRateChecked"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as api from "../../utils/api"
import GgdCard from "../../components/GgdCard";
import * as deptApi from "@/api/system/dept";
import * as accountApi from "@/api/system/account";

export default {
    props: {
        dataType: {
            type: Number,
            require: false,
            default: 1
        }
    },
    name: "Index",
    components: {GgdCard},
    data() {
        return {
            tableData: [],
            amount: 0,
            orderTotalCount: 0,
            orderSuccessCount: 0,
            duration: "",
            dail: 0,
            effectiveQuantity: 0,
            connectionVolume: 0,
            consultCount: 0,
            form: {type: 1, startToEndTime: []},
            adviserList: [],
            loading: true,
            removeDept: [],
            Options: {
                turnoverChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).turnoverChecked : true,//流水
                turnoverRepiarChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).turnoverRepiarChecked : false,//流水
                opportunitiesNumChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).opportunitiesNumChecked : true,//机会数
                firstConsultationNumChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).firstConsultationNumChecked : true,//首咨数
                stockCountNumChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).stockCountNumChecked : true,//首咨数
                dialNumChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).dialNumChecked : true,//拨打量
                frequencyChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).frequencyChecked : true,//拨打频次
                callsNumChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).callsNumChecked : true,//拨打机会数
                connectionVolumeChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).connectionVolumeChecked : true,//接通量
                connectionRateChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).connectionRateChecked : true,//接通率
                effectiveQuantityChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).effectiveQuantityChecked : true,//有效量
                totalCallDurationChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).totalCallDurationChecked : true,//总通话时长
                averageDurationChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).averageDurationChecked : true,//平均单通时长
                orderVolumeChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).orderVolumeChecked : true,//订单量
                singleQuantityChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).singleQuantityChecked : true,//成单量
                conversionRateChecked: localStorage.getItem('Options') ? JSON.parse(localStorage.getItem('Options')).conversionRateChecked : true,//转化率
            },
            deptList: [],
            showDeptList: [],
            startToEndTimeOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '昨天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            end.setTime(end.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '本周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            const firstDayOfWeek = start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1);
                            start.setDate(firstDayOfWeek);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '本月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(end.getFullYear(), end.getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            }
        }
    },
    methods: {
        keyboard(e) {
            if (e.keyCode === 13) {
                this.doSearch()
            }
        },
        findDept() {
            deptApi.treeDept().then(response => {
                let res = response.data
                this.getChild(res, 0)
            });
        },
        getChild(r, i) {
            let str = ""
            for (let j = 0; j < i; j++) {
                str += '—'
            }
            for (let c in r) {
                this.deptList.push({"title": str + r[c].title, "id": r[c].id, "name": r[c].title})
                if (r[c].children) {
                    this.getChild(r[c].children, i + 1)
                }
            }
        },
        indexInfo: function () {
            api.indexInfo({}, this.dataType).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.amount = resEntity.amount
                    this.orderTotalCount = resEntity.orderTotalCount
                    this.orderSuccessCount = resEntity.orderSuccessCount
                    this.duration = resEntity.duration
                    this.dail = resEntity.dail
                    this.effectiveQuantity = resEntity.effectiveQuantity
                    this.consultCount = resEntity.consultCount
                }
            })
        },
        getAdviserList: function () {
            accountApi.adviserList().then(res => {
                if (res.success) {
                    this.adviserList = res.data
                }
            })
        },
        getRowKeys(row) {
            return row.id + '-' + row.accountId + '-' + row.parentId
        },
        /**
         *加载表格主体数据
         */
        superStatisticsCount: function (params) {
            // params.deptId = getInformationField('deptId');
            api.superStatisticsCount(params).then(res => {
                if (res.success) {
                    this.tableData = res.data
                    this.loading = false
                }
            })
        },
        doSearch() {
            if (this.form.startToEndTime) {
                this.form.startTime = this.form.startToEndTime[0] + ' 00:00:00'
                this.form.endTime = this.form.startToEndTime[1] + ' 23:59:59'
            }
            this.superStatisticsCount(this.form)
        },
        toPersonInfo(accountId, accountName) {
            let query = {accountId: accountId, accountName: accountName}
            this.$router.push({path: '/DataAnalysis/PersonInfo', query: query})
        },
        /**
         * 选择项
         * */
        OptionsChange() {
            let Options = JSON.stringify(this.Options);
            localStorage.setItem('Options', Options);
        },
        handleDeptCheck(params, deptIds) {
            this.loading = true
            if (params) {
                //如果是总部门，就重新请求后台数据，因为可以在取消总公司之后有其它的删除部门的操作
                if (deptIds === 1) {
                    this.superStatisticsCount(this.form)
                    return
                }
                //从removeDeptData中获取部门对象
                for (let index in this.removeDept) {
                    let deptObj = this.removeDept[index]
                    if (deptObj.dept.id === deptIds) {
                        //添加部门展示
                        this.showDeptData(this.tableData, deptIds, deptObj)
                        this.removeDept.splice(index, 1)
                        break
                    }
                }
            } else {
                //移除部门展示
                this.removeDeptData(this.tableData, deptIds)
            }
            this.loading = false
        },
        /**
         * 删除部门数据
         * @param deptList
         * @param deptId
         */
        removeDeptData(deptList, deptId) {
            if (deptList) {
                for (let index in deptList) {
                    if (deptList[index].children) {
                        this.removeDeptData(deptList[index].children, deptId)
                    }
                    if (deptList[index].id === deptId) {
                        let dept = deptList.splice(index, 1)
                        let obj = {"index": index, "dept": dept[0]}
                        this.removeDept.push(obj)
                        break;
                    }
                }
            }
        },
        /**
         * 展示已经删除的部门数据
         * @param deptList
         * @param deptIds
         * @param depts 需要添加的部门
         */
        showDeptData(deptList, deptIds, depts) {
            if (deptList) {
                for (let dept of deptList) {
                    let children = dept.children
                    if (children) {
                        this.showDeptData(children, deptIds, depts)
                    }
                    if (dept.id == depts.dept.parentId) {
                        children.splice(depts.index, 0, depts.dept)
                    }
                }
            }
        },
      getNow() {
        var now = new Date();
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth(); //得到月份
        var date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, '0');
        date = date.toString().padStart(2, '0');
        var defaultDate = `${year}-${month}-${date}`;
        return defaultDate;
      }
    },
    mounted() {
        // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
        this.indexInfo()
        this.getAdviserList()
        this.superStatisticsCount(this.form)
        this.findDept()
        this.form.startToEndTime.push(this.getNow(), this.getNow());
    },
    updated() {
        if (this.$refs.dataTab && this.tableData.length > 0) {
            this.$refs.dataTab.toggleRowExpansion(this.tableData[0], true);
        }
    },
}
</script>

<style scoped>

</style>
